const About = () => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">Over mij</h2>
      <span className="section__subtitle">Mijn verhaal</span>
      <div className="about__container container">
        <div className="about__wrapper">
          <div className="about__text">
            <p className="paragraph-one">
              Toen ik jonger was, bracht ik veel tijd door achter de computer, ik speelde games en leerde de basisprincipes van programmeren. Maar hoewel ik een passie had voor technologie, koos ik destijds voor een andere carrière.
              <br/><br/>
              Jaren later, toen ik uit mijn andere werk geen voldoening meer haalde, begon ik mij af te vragen wat ik echt wilde doen met mijn leven. Ik dacht terug aan mijn passie voor technologie en besefte dat ik die passie nooit echt had losgelaten.
            </p>
            <p className="paragraph-two">
              Ik besloot te investeren in mijzelf. Ik deed mee aan een 3 maand durende Bootcamp in Development, rondde een thuisstudie tot Full-Stack Developer af en volgde een Traineeship als Software Developer. 
              <br/><br/>
              Naarmate ik meer leerde over de wereld van development, begon ik mij te beseffen dat mijn passie en specialiteit ligt bij Front-End Development. Daarom ben ik op zoek naar een werkplek waar ik als Front-End Developer mijn kennis en vaardigheden verder kan ontwikkelen en in de praktijk kan brengen.
            </p>
          </div>
          <div className="image-border">
            <div className="main-image"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About