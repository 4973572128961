import React, { useEffect } from 'react'
import { UilGraduationCap, UilBriefcaseAlt, UilCalendarAlt } from '@iconscout/react-unicons'

const Qualification = () => {
  useEffect(()=>{
    const tabs = document.querySelectorAll('[data-target]'),
    tabContents = document.querySelectorAll('[data-content]')

    tabs.forEach(tab => {
    tab.addEventListener('click', () => {
      const target = document.querySelector(tab.dataset.target)

      tabContents.forEach(tabContents => {
        tabContents.classList.remove('qualification__active')
      })
      target.classList.add('qualification__active')

      tabs.forEach(tab => {
        tab.classList.remove('qualification__active')
      })
      tab.classList.add('qualification__active')
    })
    })
  }, []);

  return (
    <section className="qualification section" id="qualification">
      <h2 className="section__title">Ervaring</h2>
      <span className="section__subtitle">Mijn persoonlijke reis</span>
      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div className="qualification__button button--flex qualification__active" data-target="#education">
            <UilGraduationCap className="qualification__icon" />
            Opleiding
          </div>
          <div className="qualification__button button--flex" data-target="#work">
            <UilBriefcaseAlt className="qualification__icon" />
            Werk
          </div>
        </div>
        <div className="qualification__sections">
          {/* Qualification content 1 */}
          <div className="qualification__content qualification__active" data-content id="education">
            {/* Qualification 1 */}
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">React Native Cursussen</h3>
                <span className="qualification__subtitle">Udemy</span>
                <div className="qualification__calender">
                  <UilCalendarAlt className="calendar__icon" />
                  <p>2022</p>
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            {/* Qualification 2 */}
            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">Full-Stack Development</h3>
                <span className="qualification__subtitle">Winc Academy</span>
                <div className="qualification__calender">
                <UilCalendarAlt className="calendar__icon" />
                  <p>2021 - 2022</p>
                </div>
              </div>
            </div>
            {/* Qualification 3 */}
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Bootcamp Full-Stack Development</h3>
                <span className="qualification__subtitle">CodeGorilla</span>
                <div className="qualification__calender">
                <UilCalendarAlt className="calendar__icon" />
                  <p>2018 - 2019</p>
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            {/* Qualification 4 */}
            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
              </div>
              <div>
                <h3 className="qualification__title">Het leven voor ik developer werd</h3>
                <span className="qualification__subtitle">Planeet aarde</span>
                <div className="qualification__calender">
                  <UilCalendarAlt className="calendar__icon" />
                  <p>1990 - 2018</p>
                </div>
              </div>
            </div>
          </div>
          {/* Qualification content 2 */}
          <div className="qualification__content" data-content id="work">
            {/* Qualification 1 */}
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Trainee Software Developer</h3>
                <span className="qualification__subtitle">GMU</span>
                <div className="qualification__calender">
                <UilCalendarAlt className="calendar__icon" />
                  <p>2022</p>
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            {/* Qualification 2 */}
            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">Klantenservice Medewerker IT Support</h3>
                <span className="qualification__subtitle">YourHosting</span>
                <div className="qualification__calender">
                <UilCalendarAlt className="calendar__icon" />
                  <p>2019 - 2021</p>
                </div>
              </div>
            </div>
            {/* Qualification 3 */}
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Financieel Administratief Medewerker</h3>
                <span className="qualification__subtitle">BabyPlanet.nl</span>
                <div className="qualification__calender">
                <UilCalendarAlt className="calendar__icon" />
                  <p>2016 -2017</p>
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                {/* <span className="qualification__line"></span> */}
              </div>
            </div>
            {/* Qualification 4 */}
            {/* <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
              </div>
              <div>
                <h3 className="qualification__title">Cosmeotologist</h3>
                <span className="qualification__subtitle">Huidpraktijk de Bruyn</span>
                <div className="qualification__calender">
                <UilCalendarAlt className="calendar__icon" />
                  <p>2009 - 2013</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Qualification