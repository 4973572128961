import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import SVG from './layout/components/Svg'
import Homepage from './layout/Homepage'
import './styles/App.css'
import Fade from 'react-reveal/Fade'
// import TheHotelLobby from './layout/main/portfolio/TheHotelLobby'
// import StudentDashboard from './layout/main/portfolio/StudentDashboard'

const App = () => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])


  return (
    loading ? <SVG /> : 
    <Routes>
      <Route path="/" element={<Fade><Homepage /></Fade>} />
    </Routes>
  )
}

export default App