import Navbar from './components/Navbar'

const Header = () => {
  return (
    <header className="header" id="header">
      <Navbar />
    </header>
  )
}

export default Header