import React, { useEffect} from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { UilEstate, UilBriefcaseAlt, UilScenery, UilUser, UilTimes, UilApps } from '@iconscout/react-unicons'

const Navbar = () => {
  useEffect(()=>{
    const toggleMenu = () => {
      document.getElementById('nav-menu').classList.toggle('show-menu')
    }

    const linkAction = () => {
      const navMenu = document.getElementById('nav-menu')
      navMenu.classList.remove('show-menu')
    }
    document.querySelectorAll('.nav__link').forEach(n => n.addEventListener('click', linkAction))

    const scrollActive = () => {
      const sections = document.querySelectorAll('section[id]')
      const scrollY = window.pageYOffset
  
      sections.forEach(current => {
        const sectionHeight = current.offsetHeight
        const sectionTop = current.offsetTop - 50;
        let sectionId = current.getAttribute('id')

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
            document.querySelector('.nav__menu a[href*=' + sectionId + ']').classList.add('active-link')
        } else {
            document.querySelector('.nav__menu a[href*=' + sectionId + ']').classList.remove('active-link')
        }
      })
    }

    const scrollHeader = () => {
      const nav = document.getElementById('header')
      if (window.scrollY >= 80) {
        nav.classList.add('scroll-header')
        document.getElementById('home').style.paddingTop='56px'
      } else {
        if (nav !== null) {
          nav.classList.remove('scroll-header')
          document.getElementById('home').style.paddingTop='4rem'
        }
      }
    }

    window.addEventListener('scroll', scrollHeader)
    window.addEventListener('scroll', scrollActive)
    document.getElementById('nav-close').addEventListener('click', toggleMenu)
    document.getElementById('nav-toggle').addEventListener('click', toggleMenu)
  }, []);

  return (
    <nav className="nav container">
      <AnchorLink href='#home' className="nav__logo">Annabel</AnchorLink>
      <div className="nav__menu" id="nav-menu">
        <ul className="nav__list grid">
          <li className="nav__item">
            <AnchorLink href='#home' className="nav__link active-link">
              <UilEstate className="nav__icon" /> Home
            </AnchorLink>
          </li>
          <li className="nav__item">
            <AnchorLink href='#about' className="nav__link">
              <UilUser className="nav__icon" /> Over mij
            </AnchorLink>
          </li>
          <li className="nav__item">
            <AnchorLink href='#portfolio' className="nav__link">
              <UilScenery className="nav__icon" /> Portfolio
            </AnchorLink>
          </li>
          <li className="nav__item">
            <AnchorLink href='#qualification' className="nav__link">
              <UilBriefcaseAlt className="nav__icon" /> Ervaring
            </AnchorLink>
          </li>
        </ul>
        <UilTimes className="nav__close" id="nav-close" />
      </div>
      <div className="nav__btns">
        <div id="nav-toggle">
          <UilApps className="nav__toggle" />
        </div>
      </div>
    </nav>
  )
}

export default Navbar