import Home from './main/Home'
import About from './main/About'
import Portfolio from './main/Portfolio'
import Qualification from './main/Qualification'
import ScrollTop from './components/ScrollTop'

const Main = () => {
  return (
    <>
      <Home />
      <About />
      <Portfolio />
      <Qualification />
      <ScrollTop />
    </>
  )
}

export default Main