import React from 'react'
import Card from '../components/Card'
import cardsData from '../../data/cardsData'

const Portfolio = () => {
  const cards = []
  
  cardsData.forEach(card => {
    cards.push(
      <Card key={card.id} values={card}/>
    )
  })

  return (
    <section className="portfolio section" id="portfolio">
      <h2 className="section__title">Portfolio</h2>
      <span className="section__subtitle">Meest recente werk</span>
      <div className="portfolio__container grid">
        <div className="cards flex">
          {cards}
        </div>
      </div>
    </section>
  )
}

export default Portfolio