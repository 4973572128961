const Svg = () => {
  return (
    <div className="loader-container">
      <svg 
        className="loading"
        viewBox="119.718 92.723 269.953 312.793" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsbx="https://boxy-svg.com" 
        width="100" 
        height="100"
      >
        <path 
          d="M 254.108 100.704 L 381.674 175.528 L 381.674 325.176 L 254.108 400 L 126.542 325.176 L 126.542 175.528 Z" 
          style={{fill: "rgba(216, 216, 216, 0)", stroke: "rgb(241, 79, 4)", strokeWidth: "7px", strokeLinecap: "round"}} 
          bxshape="n-gon 254.108 250.352 147.3 149.648 6 0 1@5d695b0d" 
          className="svg-elem-1"
        ></path>
        <path 
          d="M 219.112 252.931 Q 219.44 252.931 219.625 252.76 Q 219.81 252.589 219.81 252.343 Q 219.81 252.111 219.625 251.933 Q 219.44 251.755 219.126 251.755 L 216.952 251.755 L 218.483 247.572 L 225.087 247.572 L 226.55 251.755 L 224.007 251.755 Q 223.692 251.755 223.515 251.926 Q 223.337 252.097 223.337 252.329 Q 223.337 252.576 223.521 252.753 Q 223.706 252.931 224.034 252.931 L 229.038 252.931 Q 229.353 252.931 229.537 252.753 Q 229.722 252.576 229.722 252.329 Q 229.722 252.097 229.551 251.926 Q 229.38 251.755 229.065 251.755 L 227.985 251.755 L 223.036 237.81 Q 222.722 236.921 221.929 236.921 L 217.786 236.921 Q 217.472 236.921 217.294 237.099 Q 217.116 237.277 217.116 237.509 Q 217.116 237.755 217.294 237.926 Q 217.472 238.097 217.8 238.097 L 220.767 238.097 L 215.503 251.755 L 214.341 251.755 Q 214.026 251.755 213.849 251.933 Q 213.671 252.111 213.671 252.343 Q 213.671 252.589 213.849 252.76 Q 214.026 252.931 214.354 252.931 L 219.112 252.931 Z M 221.956 238.275 L 224.677 246.437 L 218.935 246.437 Z" 
          transform="matrix(6.904377, 0, 0, 7.559102, -1280.328735, -1601.087036)" 
          style={{fill: "rgb(241, 79, 4)", whiteSpace: "pre"}}
          className="svg-elem-2"
        ></path>
      </svg>
    </div>
  )
}

export default Svg