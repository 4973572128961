const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__bg">
        <p className="footer__copy">
          Designed & Built by 
          <br />
          Annabel Krijgsman
        </p>
      </div>
    </footer>
  )
}

export default Footer