import thlImage from '../images/portfolio/thl/thl-landing.png'
import mwsImage from '../images/portfolio/mws/mws-logo.png'
import fvkImage from '../images/portfolio/fvk/fvk-logo.png'
import stdashImage from '../images/portfolio/stdash/stdash.png'
import dapHomeImage from '../images/portfolio/dap/dap-home.png'
import mfinderImage from '../images/portfolio/mfinder/mfinder-home.png'

const cardsData = [
  {
    id:1,
    src:mwsImage,
    pills:[
      {
        id:0,
        type: "React"
      },
      {
        id:1,
        type: "SCSS"
      },
      {
        id:2,
        type: "Logo Design"
      },
      {
        id:3,
        type: "API"
      }
    ],
    title:"Marbel Web Studios",
    href:"https://marbelwebstudios.com",
    text:"Bezoek website"
  },
  {
    id:2,
    src:fvkImage,
    pills:[
      {
        id:0,
        type: "Webdesign"
      },
      {
        id:1,
        type: "Banner design"
      },
      {
        id:2,
        type: "UI/UX"
      }
    ],
    title:"Foto van Keulen",
    href:"https://fotovankeulen.nl",
    text:"Bezoek website"
  },
  {
    id:3,
    src:thlImage,
    pills:[
      {
        id:0,
        type: "React"
      },
      {
        id:1,
        type: "SCSS"
      },
      {
        id:2,
        type: "Logo Design"
      },
      {
        id:3,
        type: "UI/UX"
      }
    ],
    title:"The Hotel Lobby",
    href:"https://thehotellobby.nl",
    text:"Bezoek website"
  },
  {
    id:4,
    src:stdashImage,
    pills:[
      {
        id:0,
        type: "React"
      },
      {
        id:1,
        type: "SCSS"
      },
      {
        id:2,
        type: "Recharts"
      },
      {
        id:3,
        type: "Design"
      }
    ],
    title:"Studenten Dashboard",
    href:"https://dribbble.com/shots/20899830-Studenten-Dashboard",
    text:"Lees meer"
  },
  {
    id:5,
    src:dapHomeImage,
    pills:[
      {
        id:0,
        type: "HTML"
      },
      {
        id:1,
        type: "SCSS"
      },
      {
        id:2,
        type: "React"
      },
      {
        id:3,
        type: "Data Management"
      }
    ],
    title:"Tandarts Planner",
    href:"https://dribbble.com/shots/20899481-Tandarts-planner",
    text:"Lees meer"
  },
  {
    id:6,
    src:mfinderImage,
    pills:[
      {
        id:0,
        type: "HTML"
      },
      {
        id:1,
        type: "CSS"
      },
      {
        id:2,
        type: "JavaScript"
      },
      {
        id:3,
        type: "IMDB API"
      }
    ],
    title:"Film zoeker",
    href:"https://dribbble.com/shots/20899642-Film-zoeker",
    text:"Lees meer"
  }
]

export default cardsData