import Pill from './Pill'

const Card = (card) => {
  let pillsArray = []

  card.values.pills.forEach(pill => {
    pillsArray.push(
      <Pill key={pill.id} values={pill}/>
    )
  })

  return (
    <>
      <div className="card" key={card.values.id}>
        <div className="card__container">
          <div className="card__image">
            <img src={card.values.src} alt="Avatar" className="image" />
          </div>
          <div className="card__content">
            <div className="card__header">
              {pillsArray}
            </div>
            <div className="card__title">{card.values.title}</div>
            <div className="card__footer">
              <a className="card__link" href={card.values.href} target="_blank" rel="noreferrer">
                {card.values.text}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Card