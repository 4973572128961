import React from 'react'
import { UilLinkedinAlt, UilGithubAlt, UilDownloadAlt } from '@iconscout/react-unicons'
import { Blob } from '../components/Blob'
import Resume from '../../pdf/resume.pdf'
import Fade from 'react-reveal/Fade'

const Home = () => {
  return (
    <section className="home section" id="home">
      <div className="home__container container grid">
        <div className="home__content grid">
          <Fade delay={1000}>
            <div className="home__social">
              <a href="https://www.linkedin.com/in/annabelkrijgsman/" target="_blank" rel="noreferrer">
                <UilLinkedinAlt className="home__social-icon" />
              </a>
              <a href="https://github.com/annabelkrijgsman" target="_blank" rel="noreferrer">
                <UilGithubAlt className="home__social-icon" />
              </a>
            </div>
            <div className="home__img">
              <Blob />
            </div>
          </Fade>
          <Fade delay={2000}>
            <div className="home__data">
              <h1 className="home__title">Hi, ik ben <span className="title__color">Annabel</span></h1>
              <h3 className="home__subtitle"><span className="title__color">Front-end</span> Developer</h3>
              <p className="home__description">Nieuwsgierige en creatieve probleemoplosser, met oog voor detail.</p>
              <a href={ Resume } download="AnnabelKrijgsman_CV" className="button button--flex">
                Download CV <UilDownloadAlt className="button__icon" />
              </a>
            </div>
          </Fade>
        </div>
        {/* <div className="home__scroll">
          <a href="#about" className="home__scroll-button button--flex">
            <i className="uil uil-mouse-alt home__scroll-mouse"></i>
            <span className="home__scroll-name">Scroll down</span>
            <i className="uil uil-arrow-down home__scroll-arrow"></i>
          </a>
        </div> */}
      </div>
    </section>
  )
}

export default Home